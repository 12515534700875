@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap"); /* El yazısı fontunu buradan getiriyoruz */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root,
.container {
  height: 100%;
  margin: 0;
}

/* Yükleme ekranı */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}
.refinery {
  border-radius: 17px;
}
.loading-text {
  font-size: 24px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: black;
}

.dot {
  animation: dot-blink 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* PDF Görünümü İçin */
.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.pdf-viewer {
  width: 80%;
  height: 90%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

/* Video ve diğer içerik */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.container {
  background-image: url("./background.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-title {
  font-family: "Pacifico", cursive;
  font-size: 36px;
  color: white !important;
  margin-bottom: 40px;
  text-align: center;
  line-height: 27px;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;
}

.apps {
  display: flex;
  flex-wrap: wrap; /* Sıra dolduğunda alt satıra geçmesi için */
  gap: 10px;
  justify-content: center; /* Ortalamak için */
}

.badge {
  position: absolute;
  right: 10px;
  bottom: 9px;
  padding: 3px 7px;
  font-size: 8px;
  font-weight: bold;
  color: white;
  border-radius: 12px;
  text-align: center;
}

/* Soon badge - Altın ve gradient */
.soon-badge {
  background: linear-gradient(90deg, #ffcc00, #ff9900);
}

/* Live badge - Renkli ve gradient */
.live-badge {
  background: linear-gradient(120deg, #86ef05, #00bfff, #f70fff);
}

/* App kartlarını düzenleyin */
.app {
  position: relative; /* Rozetlerin doğru konumlanması için */
  padding: 4px;
  border-radius: 20px;
  border: 1px solid rgba(243, 243, 243, 0.575);
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.app:hover {
  transform: scale(1.1);
}

.app p {
  font-weight: bold;
  color: white;
  font-size: 12px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.app-icon {
  width: 100px;
  height: auto;
  border-radius: 16px !important;
}

.bottom-logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: auto;
}

footer {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  color: white;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
}

footer p {
  margin: 0;
  backdrop-filter: blur(10px);
  border-radius: 5px;
  padding: 5px;
}

.contact-link {
  color: white;
  text-decoration: underline;
}

.contact-link:hover {
  color: #ffcc00;
}

/* Mobil Cihazlar İçin Düzenlemeler */
@media (max-width: 768px) {
  .app-icon {
    width: 80px;
  }

  .background-video {
    top: -50px;
  }

  .bottom-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
  }

  .app-title {
    font-size: 24px;
  }

  .container {
    background-image: url("./background_mobile.png") !important;
  }

  footer {
    display: flex;
    justify-content: center;
    padding: 5px !important;
    color: white;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.privacy-policy-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-content section {
  margin-bottom: 30px;
}

.privacy-content h2 {
  color: #444;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.privacy-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-content ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #666;
}

.privacy-content li {
  margin-bottom: 8px;
  line-height: 1.4;
}
